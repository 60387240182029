import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["progress", "stats", "progressButton", "statsButton"];

  connect() {
    console.log("MatchToggle Controller connected");
    this.showProgress(); // Domyślnie pokazujemy "Przebieg"
  }

  showProgress() {
    // Ukrywanie statystyk i pokazywanie przebiegu
    if (this.hasProgressTarget) {
      this.progressTarget.style.display = "block";
    }
    if (this.hasStatsTarget) {
      this.statsTarget.style.display = "none";
    }

    // Zmiana stylu aktywnego przycisku
    this.activateButton(this.progressButtonTarget, "bg-blue-700", "bg-blue-500");
    this.deactivateButton(this.statsButtonTarget, "bg-gray-700", "bg-gray-500");
  }

  showStats() {
    // Ukrywanie przebiegu i pokazywanie statystyk
    if (this.hasProgressTarget) {
      this.progressTarget.style.display = "none";
    }
    if (this.hasStatsTarget) {
      this.statsTarget.style.display = "block";
    }

    // Zmiana stylu aktywnego przycisku
    this.activateButton(this.statsButtonTarget, "bg-gray-700", "bg-gray-500");
    this.deactivateButton(this.progressButtonTarget, "bg-blue-700", "bg-blue-500");
  }

  activateButton(button, activeClass, inactiveClass) {
    button.classList.add(activeClass);
    button.classList.remove(inactiveClass);
  }

  deactivateButton(button, activeClass, inactiveClass) {
    button.classList.add(inactiveClass);
    button.classList.remove(activeClass);
  }
}


