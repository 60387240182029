// app/javascript/controllers/navbar_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["menu", "dropdown", "arrow"]

    toggleMenu() {
        this.menuTarget.classList.toggle("hidden");
    }

    toggleDropdown() {
        const dropdown = this.dropdownTarget;
        const arrow = this.arrowTarget;

        if (window.innerWidth < 768) { // Mobile
            dropdown.classList.toggle("hidden");
            arrow.classList.toggle("rotate-180");
        } else { // Desktop
            dropdown.classList.toggle("hidden");
            if (!dropdown.classList.contains("hidden")) {
                dropdown.classList.remove("md:opacity-0", "md:-translate-y-2");
                dropdown.classList.add("md:opacity-100", "md:translate-y-0");
                arrow.classList.add("rotate-180");
            } else {
                dropdown.classList.remove("md:opacity-100", "md:translate-y-0");
                dropdown.classList.add("md:opacity-0", "md:-translate-y-2");
                arrow.classList.remove("rotate-180");
            }
        }
    }

    closeDropdown(event) {
        if (!this.element.contains(event.target) && window.innerWidth >= 768) {
            const dropdown = this.dropdownTarget;
            const arrow = this.arrowTarget;
            dropdown.classList.remove("md:opacity-100", "md:translate-y-0");
            dropdown.classList.add("hidden", "md:opacity-0", "md:-translate-y-2");
            arrow.classList.remove("rotate-180");
        }
    }
}

document.addEventListener("click", (event) => {
    const controllers = document.querySelectorAll("[data-controller='navbar']");
    controllers.forEach(controller => {
        const navbar = controller.__stimulusController;
        if (navbar) navbar.closeDropdown(event);
    });
});